define("discourse/plugins/discourse-nationalflags/discourse/components/user-nationalflags-preferences", ["exports", "discourse/models/store", "ember-addons/ember-computed-decorators", "discourse/lib/ajax"], function (_exports, _store, _emberComputedDecorators, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = Ember.Component.extend((_dec = (0, _emberComputedDecorators.default)('model.custom_fields.nationalflag_iso'), (_obj = {
    layoutName: 'javascripts/discourse/templates/connectors/user-custom-preferences/user-nationalflags-preferences',
    flagsource() {
      return this.get('model.custom_fields.nationalflag_iso') == null ? 'none' : this.get('model.custom_fields.nationalflag_iso');
    }
  }, (_applyDecoratedDescriptor(_obj, "flagsource", [_dec], Object.getOwnPropertyDescriptor(_obj, "flagsource"), _obj)), _obj)));
});